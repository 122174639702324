.home-heroes {
  display: flex;
  justify-content: space-around;
  gap: 1rem;
}

.page-main {
  padding: 1rem 3rem 0rem 3rem;
}

.home-hero {
  z-index: 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.hero-time {
  color: #555454;
  font-size: 1.8rem;
  font-weight: 700;
}

.hero-clockin-container {
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
  gap: 1rem;
  padding: 1rem;
}

.hero-button {
  width: 100%;
  height: 7rem;
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hero-button span {
  font-size: 1.5rem;
}

.hero-button i {
  font-size: 2.5rem;
}

.hero-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.hero-stats-hours {
  align-items: center;
  gap: 2rem;
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.hero-stats-main {
  display: flex;
  width: 100%;
}

.hero-stats-hours {
  font-size: 1.3rem;
  width: 50%;
}

.hero-stats-hours-text {
  font-weight: 600;
}

.hero-stats-status span {
  background-color: green;
  color: #fff;
  padding: 0.2rem 2rem 0.2rem 2rem;
  border-radius: 20px;
}

.hero-activities {
  padding: 1rem;
}

.hero-activity {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #c6c6c6;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.hero-activity-timestamp {
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  gap: 0;
  margin-left: 1rem;
}

.hero-activity i {
  font-size: 1.5rem;
  font-weight: 300;
  margin-right: 1rem;
}

.hero-activity-type {
  font-size: 1.1rem;
}

.hero-activity-timestamp-time {
  font-weight: 600;
}

.hero-activity-timestamp-date {
  font-size: 0.8rem;
}

.bg-clockin {
  color: var(--success-color);
}

.bg-break {
  color: var(--warning-color);
}
