.app-container {
  display: flex;
}

/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

/*=============== VARIABLES CSS ===============*/
:root {
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --primary-color: #20655f;
  --secondary-color: #f8aa27;
  --container-color: #f8f4ed;
  --danger-color: rgb(226, 83, 83);
  --warning-color: rgb(255, 187, 60);
  --success-color: rgb(75, 139, 75);
}

.page-container {
  margin: 1px;
  background-color: var(--container-color);
  height: 100vh;
  width: 100%;
}

.page {
  width: 100%;
  margin-top: 80px;
}

.page-header {
  display: flex;
  align-items: center;
  padding-left: 3rem;
  padding-top: 2rem;
  gap: 2rem;
  justify-content: center;
}

.page-title {
  font-size: 2rem;
}

.container-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-title {
  color: #747373;
  font-size: 1.2rem;
}

.container {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  flex: 1; /* Allows the containers to expand to fill available space */
}

.section-title {
  font-size: 2.5rem;
  font-weight: 200;
  margin-bottom: 0.5rem;
  font-family: "Young Serif";
  display: inline;
  text-transform: capitalize;
  text-align: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  margin-bottom: 2rem;
}

.custom-button {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #888;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  height: 3rem;
  width: 8rem;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3;
}

.link {
  text-decoration: none;
}

.no-scroll {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .section-title {
    font-size: 2rem !important;
  }

  .section {
    padding-top: 2rem;
    padding-bottom: 0rem !important;
    margin-bottom: 0rem !important;
  }
}

button {
  cursor: pointer;
}
