.profile-container {
}

.profile-row {
  font-size: 1.3rem;
  padding: 0.5rem;
}

.container-main {
  display: flex;
}

.profile-main-img {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15rem;
}

.profile-main-img-image {
  height: 20rem;
  width: 20rem;
}
