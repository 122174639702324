.header {
  height: 80px;
  background-color: var(--text-color);
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 1px 3px -2px black;
  z-index: 1000; /* Ensure header is above other elements */
  position: fixed;
  background-color: #fff;
}

.header-left {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
}

.burger-button {
  font-size: 2rem;
  padding-left: 1rem;
  border: none;
  background-color: transparent;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-logo-text {
  color: var(--title-color);
  font-size: 2rem;
  font-weight: 600;
}
.header-logo-img {
  height: 4.5rem;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-right: 3rem;
  gap: 4rem;
}

.header-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.header-status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: rgb(45, 139, 45);
}
.header-status-text {
  font-weight: 500;
}

.header-user {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  color: black;
}

.header-user-img {
  height: 3rem;
  border-radius: 50%;
}

.header-user-text {
  font-size: 1rem;
}

.header-active {
  color: var(--success-color);
}

.header-inactive {
  color: var(--danger-color);
}
.header-break {
  color: var(--warning-color);
}
.header-active-bg {
  background-color: var(--success-color);
}

.header-break-bg {
  background-color: var(--warning-color);
}
.header-inactive-bg {
  background-color: var(--danger-color);
}
