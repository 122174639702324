/* UserTable.css */

.user-table-container {
  width: 100%;
  overflow-x: auto;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table tbody td {
  text-align: center; /* Align both column names and cell content to the center */
  border-bottom: 1px solid #e9e9e9;
  padding: 0.5rem;
  padding-right: 0;
}

.user-table th {
  text-align: center; /* Align column names to center */
}

.user-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  vertical-align: middle; /* Center the picture vertically */
}

.user-table tbody tr:hover {
  background-color: #f6f6f6;
}

.users-status {
  background: red;
  color: white;
  border-radius: 50px;
  font-size: 0.9rem;
  text-transform: capitalize;
}

.active-status {
  background-color: var(--success-color);
}
.inactive-status {
  background-color: var(--danger-color);
}
.break-status {
  background-color: var(--warning-color);
}

.users-icon {
  font-size: 1.3rem;
  cursor: pointer;
}
