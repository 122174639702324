/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0; /* Adjusted top value */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure the modal overlay is above other content */
}
.modal {
  background-color: white;
  padding: 2rem 3rem 2rem 3rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  position: relative;
  top: -5%; /* Adjusted top value */
  transform: translateY(-50%);
  justify-content: center;
}

.modal-buttons {
  padding-top: 2rem;
  display: flex;
  justify-content: right;
  gap: 1rem;
}
.modal-close-btn {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 10px;
  border: none;
  font-size: 1.2rem;
  width: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-yes {
  background-color: var(--success-color);
  color: white;
}

.button-cancel {
  border: 1px solid black;
  background-color: white;
}

.modal h2,
.modal p {
  margin: 0.1rem;
}

.modal-icon {
  display: flex;
  justify-content: center;
  font-size: 3rem;
  margin-bottom: 1rem;
}
