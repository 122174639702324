.clockin {
  color: var(--success-color);
}

.clockout {
  color: var(--danger-color);
}

.startbreak,
.endbreak {
  color: var(--warning-color);
}

.hero-activities-container {
  max-height: 300px; /* Adjust the max height as needed */
  overflow-y: auto;
}

.overflow-message {
  text-align: center;
  color: #666;
  margin-top: 5px;
}
