.sidebar {
  width: 13rem;
  height: 100vh;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  box-shadow: 1px 1px 3px -2px black;
  background-color: var(--text-color);
  z-index: 0; /* Ensure sid
  ebar is behind header */

  z-index: 999; /* Ensure the sidebar is above other content */
  overflow-y: auto; /* Allow vertical scrolling if content exceeds the viewport */
  background-color: #fff;
  padding-top: 82px;
}

.small-sidebar {
  width: 4rem;
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  height: 100vh;
  padding-top: 2rem;
}
.sidebar-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.sidebar-active {
  background-color: var(--container-color);
}

.sidebar-item {
  color: var(--title-color);
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 1rem;
  height: 2rem;
}

.sidebar-item:hover {
  color: rgb(15, 51, 22);
  cursor: pointer;
}

.sidebar i {
  font-size: 1.3rem;
}

.sidebar hr {
  height: 1px;
  width: 80%;
  background-color: rgb(208, 203, 183);
  border: none;
}

.sidebar-logout {
  position: fixed;
  display: flex;
  justify-content: center;
  bottom: 2rem;
}
