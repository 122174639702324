.status-active {
  background-color: var(--success-color);
}

.status-inactive {
  background-color: var(--danger-color);
}

.status-break {
  background-color: var(--warning-color);
}

.home-employee-status {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.home-employee-status-tag {
  color: white;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 30px;
  right: 0;
}

.disabled {
  cursor: not-allowed;
  background-color: rgb(212, 212, 212);
}

.hero-stats-status {
  display: flex;
  justify-content: left;
  width: 50%;
  font-size: 1.5rem;
  padding-left: 1rem;
  padding-top: 1rem;
}
