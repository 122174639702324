.login {
  width: 100%;
  height: 45vh;
  padding-top: 5rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  width: 25vw;
  margin: auto;
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  row-gap: 0;
  background-color: var(--container-color);
  padding: 2rem;
  border-radius: 10px;
}

.login-title {
  margin: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.login-fields input {
  height: 3rem;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  border: 1px solid var(--primary-color);
  outline: none;
  color: #000;
  font-size: 18px;
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
}

.login-container button {
  width: 100%;
  box-sizing: border-box;
  height: 3rem;
  color: white;
  background: var(--primary-color);
  border: none;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 1rem;
}

.error-message-container {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  color: red;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}

.login-container button:disabled {
  background: #5c5c5c;
  cursor: not-allowed;
}

@media screen and (max-width: 1200px) {
  .login-container {
    width: 40vw;
  }
}

@media screen and (max-width: 920px) {
  .login-container {
    width: 50vw;
  }
}
@media screen and (max-width: 760px) {
  .login-container {
    width: 70vw;
  }
}
@media screen and (max-width: 500px) {
  .login-container {
    width: 90vw;
    transform: scale(0.9);
  }
}
@media screen and (max-width: 350px) {
  .login-container {
    width: 90vw;
    transform: scale(0.8);
  }

  .login {
    padding-top: 0rem;
    padding-bottom: 0;
  }
}
